import { createNewSortInstance } from 'fast-sort';
import { Loader } from '../../components/Loader';
import { CAD as CADComponent } from '../../components/CAD';
import { CADData } from '../../types/customcad';
import { useCustomCAD } from '../../hooks/useCAD';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
});
interface ICADProps {
    LocationId?: string | null;
    handler:any
}

export const CadManagement = ({handler}:ICADProps) => {
    const {instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const { LocationId, CadName } = useLocation().state
    const cadQuery = useCustomCAD(LocationId);
    window.scrollTo({ behavior: 'smooth', top: 0 });

    const cadSites = naturalSort(cadQuery?.data?.data?.Data || []).asc(site => site.Model);
    const pending = cadSites.filter(test => 'Pending' === test.Status);
    const approved = cadSites.filter(test => 'Approved' === test.Status);
    const completed = cadSites.filter(test => 'Completed' === test.Status);
    const expired = cadSites.filter(test => 'Expired' === test.Status);

    const renderCADList = (list: CADData[],type?:any) => {
        if (cadQuery.isLoading) {
          return <Loader />;
        } else if (cadQuery.isError) {
          return <p>{cadQuery.error.message}</p>;
        } else if (list.length < 1) {
          return <p>No Custom CADs found</p>;
        } else {
          return (
            <ul className="cco_accordion">
              {list.map((site: CADData, index: any) => (
                <CADComponent key={site.LocationId + index} item={site} type={type} />
              ))}
            </ul>
          );
        }
      };
    return (
        <div id="main-content">
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">
                    <div className="cco_section cco_section_0">
                        <div className="cco_row">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/testing-personnel">Testing Personnel</a>
                                        </strong>{' '}
                                    
                                        {'>'}
                                        Practical Locations

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cco_row cco_row_0">
                            <div className="cco_col cco_col_12">
                                <div className="cco_text cco_text_1">
                                    <div className="cco_text_inner">
                                        <h1>
                                            {CadName}
                                            <span className="divider" />
                                            <small>
                                                CCO ID <strong>{activeAccount && activeAccount.idTokenClaims?.extension_CandidateId as string}</strong>
                                            </small>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div >
                            <>
                                <div className="cco_row cco_row_7">
                                    <div className="cco_col cco_col_6">
                                        <div className="cco_text cco_text_10">
                                            <div className="cco_text_inner">
                                                <h2>My Custom CADs</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cco_col cco_col_7">
                                        <div className="cco_btn cco_btn_3">
                                            {/* <a href={'#'} >Back to Prac Test Site</a> */}
                                            <Link to={'/tsc-prac'}>Back to Prac Test Site</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="cco_row cco_row_3">
                                    <div className="cco_col">

                                        <ul className="cco_menu cco_menu_4">
                                            <li className="cco_menu_item cco_menu_item_icon cco_menu_item_icon_plus">
                                                {/* <a href={`/tsc-prac/custom-cad/${LocationId}`}>Add new Custom CADs</a> */}
                                                <Link to={'/tsc-prac/custom-cad/new-cad'} state={{ eotId: LocationId }} >Add new Custom CADs</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>


                            {pending.length > 0 && (
                                <>
                                    <div className="cco_row cco_row_7">
                                        <div className="cco_col cco_col_6">
                                            <div className="cco_text cco_text_10">
                                                <div className="cco_text_inner">
                                                    <h2>My Pending Review Custom CADs</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cco_row cco_row_3">
                                        <div className="cco_col">
                                            {renderCADList(pending)}
                                        </div>
                                    </div>
                                </>
                            )}

                            {approved.length > 0 && (
                                <>
                                    <div className="cco_row cco_row_7">
                                        <div className="cco_col cco_col_6">
                                            <div className="cco_text cco_text_10">
                                                <div className="cco_text_inner">
                                                    <h2>My Approved Custom CADs</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cco_row cco_row_3">
                                        <div className="cco_col">
                                            {renderCADList(approved)}
                                        </div>
                                    </div>
                                </>
                            )}
    {completed.length > 0 && (
  <>
    <div className="cco_row cco_row_7">
      <div className="cco_col cco_col_6">
        <div className="cco_text cco_text_10">
          <div className="cco_text_inner">
            <h2>My Completed Custom CADs</h2>
          </div>
        </div>
      </div>
    </div>

    <div className="cco_row cco_row_3">
      <div className="cco_col">
        {renderCADList(completed,'Completed')}
      </div>
    </div>
  </>
)}

                            {expired.length > 0 && (
                                <>
                                    <div className="cco_row cco_row_7">
                                        <div className="cco_col cco_col_6">
                                            <div className="cco_text cco_text_10">
                                                <div className="cco_text_inner">
                                                    <h2>My Expired Custom CADs</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cco_row cco_row_3">
                                        <div className="cco_col">
                                            {renderCADList(expired)}
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>

                    </div>
                </article>
            </div>
        </div>
    );
};
